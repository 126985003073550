// db.ts
import { Channel } from "@/types/Channel";
import { Contact } from "@/types/Contact";
import { Message } from "@/types/Message";
import { User } from "@/types/User";
import Dexie, { Table } from "dexie";

export class MyDatabaseContactsDexie extends Dexie {
  // 'contacts' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  contacts!: Table<Contact>;
  chats!: Table<Contact>;
  config!: Table<User>;
  departments!: Table<any>;
  labels!: Table<any>;
  channels!: Table<Channel>;
  fastmsgs!: Table<any>;
  users!: Table<User>;
  medias!: Table<Message>;

  constructor() {
    super("model-storage");
    this.version(1).stores({
      contacts: "++id, master_id, id_api, name, pushname, isOpen",
      chats: "++id, master_id, id_api, name, pushname, timestamp",
      config: "++id, master_account, msgId",
      departments: "++id, name",
      labels: "++id, name",
      channels: "++id, type, name, session_name",
      fastmsgs: "++id, name, title",
      users: "++id, name, surname",
      medias: "++id, contactId",
    });
  }
}

export const db = new MyDatabaseContactsDexie();
